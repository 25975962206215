import React from "react";
import { Link } from "react-scroll";
import styled from "styled-components";

export const MintBtn = styled.button`
  color: #000;
  background-color: #fec750;
  border-width: 0;

  align-items: baseline;
  display: inline;

  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 24px;
  padding-right: 24px;

  font-weight: 500;
  text-align: center;
  margin: 0 auto;
  // display: flex;
  border: 1px solid transparent;
  border-radius: 100px;
  margin-left: 4px;
  margin-right: 4px;

  font-size: 16px;
  line-height: 22px;

  justify-content: center;
  cursor: pointer;

  &:hover {
    color: white;
    text-decoration: none;
  }
`;

const Navbar = ({ navClass, linkClassName }) => (
  <NavComponent navClass={navClass} linkClassName={linkClassName} />
);

export const NavComponent = ({ onClick, navClass, linkClassName }) => (
  <nav className={navClass}>
    <Link to="mint" smooth={true} className={linkClassName} onClick={onClick}>
      <MintBtn>Mint</MintBtn>
    </Link>

    {/* "Projects", "Roadmap", */}
    {["About", "Build", "Partners"].map((section, index) => (
      <Link
        key={index} // 고유한 key prop 추가
        to={section}
        smooth={true}
        className={linkClassName}
        onClick={onClick}
      >
        {section}
      </Link>
    ))}
  </nav>
);
export default Navbar;
