import Header from "./pages/Headers/Header";
import Section1 from "./pages/Section-1";
import Section2 from "./pages/Section-2";
import Section3 from "./pages/Section-3";
import Section4 from "./pages/Section-4";
import Mint from "./pages/Mint";
import Section5 from "./pages/Section-5";
import Section6 from "./pages/Section-6";
import Partners from "./pages/Partners";
import Footer from "./pages/Footer";

import "./App.css";

const App = () => {
  return (
    <div className="app">
      <Header />
      <Section1 />
      <Section2 />
      {/* <Section3 /> */}
      <Mint />
      <Section6 />
      {/* <Section4 /> */}
      {/* <Partners /> */}
      {/* <Section5 /> */}
      <Footer />
    </div>
  );
};

export default App;
