import React from "react";
import styled from "styled-components";

export const MCMFs = styled.div`
  display: flex;
  text-align: center;
  align-items: baseline;
  margin: 0 auto;
  justify-content: center;
  font-family: "Bungee";
`;

export const M1 = styled.p`
  // color: #ff9898;
  font-family: "Anton";
`;
export const C = styled.p`
  // color: #8459ff;
  font-family: "Anton";
`;
export const M2 = styled.p`
  // color: #82e0aa;
  font-family: "Anton";
`;
export const F = styled.p`
  // color: #3498db;
  font-family: "Anton";
`;

const MCMF = () => {
  return (
    <>
      <M1>M</M1>
      <C>C</C>
      <M2>M</M2>
      <F>F</F>
    </>
  );
};

export default MCMF;
