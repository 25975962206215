import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import * as s from "../styles/globalStyles";
import styled from "styled-components";
import Web3 from "web3";
import "rsuite/styles/index.less";
import "rsuite/dist/rsuite.min.css";
import { Panel, PanelGroup } from "rsuite";
import { Carousel } from "rsuite";
import { Notification, toaster } from "rsuite";
import { Loader } from "rsuite";
import { Badge } from "rsuite";

import "./Mint.css";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  font-family: "Anton";
  padding: 10px;
  font-size: 1.5rem;
  border-radius: 6px;
  border: none;
  background-color: white;
  padding: 10px;
  padding-top: 15px;
  letter-spacing: 2px;
  font-weight: bold;
  color: white;
  width: 270px;
  height: 50px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px black;
  -webkit-box-shadow: 0px 6px 0px -2px black;
  -moz-box-shadow: 0px 6px 0px -2px black;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    color: white;
  }
  @media (max-width: 565px) {
    width: 200px;
    height: 50px;
    font-size: 0.75rem;
  }

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 200px;
  height: 42px;

  background: black;
  border: 1px solid white;
  border-radius: 15px;
  padding-bottom: 15px;

  flex: none;
  order: 3;
  flex-grow: 0;

  :hover {
    box-shadow: -0px -0px 14px rgba(255, 255, 255, 0.45), 0px 0px 25px #d4dced,
      inset -0px -0px 44px rgba(255, 255, 255, 0.25),
      inset 0px 0px 34px rgba(0, 0, 0, 0.05);
  }
`;
export const CTNButton = styled.button`
  color: #000;
  font-family: "Raleway";
  font-weight: 500;
  text-align: center;
  align-items: center;
  // background: black;
  background-color: #fec750;
  border-radius: 100px;
  padding: 16px 32px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: #000;
    background-color: #fec760;
    border-width: 0;
    text-decoration-line: none;
    box-shadow: -1px -1px 14px rgba(255, 255, 255, 0.45), 1px 1px 30px #d4dced,
      inset 10px 0px 34px rgba(0, 0, 0, 0.05);
  }
`;
export const Maxbtn = styled.button`
  font-family: "Bungee";
  font-size: 0.75rem;
  border-radius: 10px;
  background-color: #f48c2c;
  font-weight: bold;
  color: white;
  width: 80px;
  height: 30px;
  cursor: pointer;
  letter-spacing: 2px;
  :hover {
    color: black;
  }
  @media (max-width: 565px) {
    width: 200px;
    height: 50px;
    font-size: 0.75rem;
  }
`;
export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: transparent;
  padding: 10px;
  font-weight: bold;
  font-size: 30px;
  color: white;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    color: silver;
  }
`;
export const LogoDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 10%;
  width: 300px;
`;
export const ResponsiveWrapper = styled.div`
  display: table;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 40%;

  height: 400px;

  border-radius: 40px;

  @media (min-width: 767px) {
    flex-direction: row;
  }
  @media (max-width: 767px) {
    width: 80%;
  }
`;
export const ResponsiveWrapperHeader = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 80px;
  padding: 10px;
  background-color: none;
  @media (min-width: 767px) {
    flex-direction: row;
  }
  @media (max-width: 565px) {
    max-height: 220px;
  }
`;
export const StyledLogo = styled.img`
  display: inline;
  width: 200px;
  @media (max-width: 767px) {
    width: 150px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;
export const StyledImg = styled.img`
  width: 450px;
  border-radius: 5px;
  @media (min-width: 900px) {
    width: 450px;
  }
  @media (min-width: 1000px) {
    width: 450px;
  }
  transition: width 0.5s;
  @media (max-width: 565px) {
    width: 200px;
  }
`;
export const Styledroad = styled.img`
  width: 100%;
  border-radius: 5px;
  transition: width 0.5s;
`;
export const StyledImgSmall = styled.img`
  width: 220px;
  height: 220px;
  border-radius: 5px;
  @media (min-width: 900px) {
    width: 220px;
    height: 220px;
  }
  @media (min-width: 1000px) {
    width: 220px;
    height: 220px;
  }
  transition: width 0.5s;
  @media (max-width: 565px) {
    width: 200px;
  }
`;
export const StyledLink = styled.a`
  font-family: "Releway";
  color: rgb(32, 129, 226);
  font-size: 20px;
  font-weight: 900;
  line-height: 1.6;
  padding-top: 5px;

  @media (max-width: 565px) {
    font-size: 0.75rem;
    letter-spacing: 2px;
    text-align: center;
  }
`;
export const WalletBox = styled.div`
  // text-decoration: none;
  // border-radius: 10px;
  // border: 2px solid white;
  // background-color: transparent;
  // //padding: 10px;
  // font-weight: bold;
  // font-size: 15px;
  // width: 180px;
  // height: 50px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // box-shadow: 0px 4px 0px -2px white;
  // -webkit-box-shadow: 0px 4px 0px -2px white;
  // -moz-box-shadow: 0px 4px 0px -2px white;
  // @media (max-width: 565px) {
  //   margin-top: 20px;

  // }
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 25px;
  gap: 10px;

  width: 200px;
  height: 42px;

  background: linear-gradient(116.39deg, #ffebff 14.15%, #fbffff 92.46%);
  box-shadow: -10px -6px 14px rgba(255, 255, 255, 0.45), 10px 14px 24px #d4dced,
    inset -10px -16px 44px rgba(255, 255, 255, 0.25),
    inset 10px 0px 34px rgba(0, 0, 0, 0.05);
  border-radius: 33.2016px;

  flex: none;
  order: 3;
  flex-grow: 0;
  // @media (min-width: 566px) and (max-width: 766px) {
  //   margin: 0 auto;
  // }
`;
export const TireFont = styled.p`
  font-size: 22px;
  font-family: "Raleway";
  font-weight: 400;
`;
export const ConnectDiv = styled.div`
  display: block;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;

  @media (max-width: 900px) {
    display: none;
  }
`;
export const ConnectDivM = styled.div`
  display: none;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;

  @media (max-width: 900px) {
    display: block;
  }
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [walletAddress, setAddress] = useState("Not Connected");
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [tokens, settokens] = useState(1);
  const [tokensPlatinum, settokensPlatinum] = useState(1);
  const [brd, setbrd] = useState("2px solid #F0F0F0F");
  const [bxsh, setbxsh] = useState("0px 0px 3px 0px #F0F0F0F");
  const [DOT, setDOT] = useState("red");
  const [whitlisted, setWhitelisted] = useState(false);
  const [isMinted, setIsMinted] = useState(false);
  const [isMintedPlatinum, setMintedPlatinum] = useState(false);
  const [type, setType] = React.useState("info");
  const [placement, setPlacement] = React.useState("topStart");

  // const [mintCountBlack, setMintCountBlack] = useState(10 - isMinted);
  // const [mintCountPlatinum, setMintCountPlatinum] = useState(
  //   20 - isMintedPlatinum
  // );

  const errmessage = (
    <Notification type={"error"} header={"error"} closable>
      Sorry, something went wrong please try again later.
    </Notification>
  );
  const txmessage = (
    <Notification type={"success"} header={"success"} closable>
      Congrats, Mint was successfull.
    </Notification>
  );
  const mntmessage = (
    <Notification type={"info"} header={"success"} closable>
      <Loader /> Minting in Progress....
    </Notification>
  );

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    DISPLAY_BLACK_COST: 0,
    DISPLAY_Platinum_COST: 0,
    WL_Display: 0,
    GAS_LIMIT: 0,
    // MAX_PER_TX: 0,
    MAX_PER_TX_BLACK: 0,
    MAX_PER_TX_Platinum: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    Telegram: "",
    Discord: "",
    Twitter: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.DISPLAY_BLACK_COST * tokens;
    let price = Web3.utils.toWei(cost.toString(), "ether");
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);

    if (tokens > 5) {
      console.error("Token count exceeds the maximum limit of 5");
      return;
    }

    console.log("Cost: ", price);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    setbrd("2px solid #F0F0F0F");
    setbxsh("0px 0px 3px 0px #F0F0F0F");
    toaster.push(mntmessage, { placement });
    blockchain.smartContract.methods
      .mintForMcmfBLACK(tokens)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: price,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
        toaster.push(errmessage, { placement });
        setbrd("2px solid #F0F0F0F");
        setbxsh("0px 0px 0px 0px #F0F0F0F");
      })
      .then((receipt) => {
        console.log(receipt);

        // setIsMinted(isMinted + tokens);
        // setIsMinted((prevIsMinted) => prevIsMinted + tokens);

        mintedForMcmfBLACK();

        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        toaster.push(txmessage, { placement });
        setbrd("2px solid #F0F0F0F");
        setbxsh("0px 0px 0px 0px #F0F0F0F");
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFTsPlatinum = () => {
    let cost = CONFIG.DISPLAY_Platinum_COST * tokensPlatinum;
    let price = Web3.utils.toWei(cost.toString(), "ether");
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);

    if (tokensPlatinum > 5) {
      console.error("Token count exceeds the maximum limit of 5");
      return;
    }

    console.log("Cost: ", price);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    setbrd("2px solid #F0F0F0F");
    setbxsh("0px 0px 3px 0px #F0F0F0F");
    toaster.push(mntmessage, { placement });
    blockchain.smartContract.methods
      .mintForMcmfPlatinum(0, tokensPlatinum)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: price,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
        toaster.push(errmessage, { placement });
        setbrd("2px solid #F0F0F0F");
        setbxsh("0px 0px 0px 0px #F0F0F0F");
      })
      .then((receipt) => {
        console.log(receipt);

        // setMintedPlatinum(isMintedPlatinum + tokensPlatinum);
        // setIsMinted(
        //   (prevIsMintedPlatinum) => prevIsMintedPlatinum + tokensPlatinum
        // );

        mintedForMcmfPlatinum();

        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        toaster.push(txmessage, { placement });
        setbrd("2px solid #F0F0F0F");
        setbxsh("0px 0px 0px 0px #F0F0F0F");
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  ////////////// in, decrement //////////////

  ////////////// BLACK Tokens
  const decrementtokens = () => {
    let newtokens = tokens - 1;
    if (newtokens < 1) {
      newtokens = 1;
    }
    settokens(newtokens);
  };

  const incrementtokens = () => {
    let newtokens = tokens + 1;
    const maxMintPerTx = 5;
    const maxMintTotal = 10;
    const remainingMintCapacity = maxMintTotal - isMinted;
    const allowedMintThisTx = Math.min(maxMintPerTx, remainingMintCapacity);

    if (newtokens > allowedMintThisTx) {
      newtokens = allowedMintThisTx;
    }
    settokens(newtokens);
  };
  ////////////// Platinum Tokens
  const decrementtokensPlatinum = () => {
    let newtokensPlatinum = tokensPlatinum - 1;
    if (newtokensPlatinum < 1) {
      newtokensPlatinum = 1;
    }
    settokensPlatinum(newtokensPlatinum);
  };

  const incrementtokensPlatinum = () => {
    let newtokensPlatinum = tokensPlatinum + 1;
    const maxMintPerTx = 5;
    const maxMintTotal = 20;
    const remainingMintCapacity = maxMintTotal - isMintedPlatinum;
    const allowedMintThisTx = Math.min(maxMintPerTx, remainingMintCapacity);

    if (newtokensPlatinum > allowedMintThisTx) {
      newtokensPlatinum = allowedMintThisTx;
    }
    settokensPlatinum(newtokensPlatinum);
  };
  ////////////// in, decrement //////////////

  const mintedForMcmfBLACK = async () => {
    if (blockchain.smartContract) {
      try {
        const ret = await blockchain.smartContract.methods
          .mintedForMcmfBLACK(blockchain.account)
          .call();
        console.log(`mintedForMcmfBLACK : ${ret}`);
        setIsMinted(ret);
      } catch (error) {
        console.error("Error while fetching mintedForMcmfBLACK:", error);
      }
    } else {
      console.log("Smart contract not initialized");
    }
  };

  const mintedForMcmfPlatinum = async () => {
    if (blockchain.smartContract) {
      try {
        const ret = await blockchain.smartContract.methods
          .mintedForMcmfPlatinum(0, blockchain.account)
          .call();
        console.log(`mintedForMcmfPlatinum : ${ret}`);
        setMintedPlatinum(ret);
      } catch (error) {
        console.error("Error while fetching mintedForMcmfPlatinum:", error);
      }
    } else {
      console.log("Smart contract not initialized");
    }
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      setAddress(
        blockchain.account.substring(0, 5) +
          " . . . " +
          blockchain.account.substring(38, 42)
      );
      setDOT("🟢");
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    if (blockchain.smartContract) {
      getData();
      mintedForMcmfBLACK();
      mintedForMcmfPlatinum();
    }
  }, [blockchain.account, blockchain.smartContract]);

  console.log(`@@@@@@@@@@`, isMintedPlatinum);
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setMintCountBlack(10 - isMinted);
  //   }, 100); // 5초마다 실행

  //   return () => clearInterval(interval); // 컴포넌트 언마운트시 인터벌 정리
  // }, [isMinted]); // isMinted가 변경될 때마다 이 효과를 재실행합니다.

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setMintCountPlatinum(20 - isMintedPlatinum);
  //   }, 100); // 5초마다 실행

  //   return () => clearInterval(interval); // 컴포넌트 언마운트시 인터벌 정리
  // }, [isMintedPlatinum]); // isMinted가 변경될 때마다 이 효과를 재실행합니다.

  return (
    <section id="mint">
      <s.Screen>
        <ConnectDivM>
          <CTNButton
            onClick={(e) => {
              e.preventDefault();
              dispatch(connect());
              getData();
            }}
          >
            {blockchain.account === "" || blockchain.smartContract === null ? (
              <>Connect Wallet</>
            ) : (
              <>
                {DOT}&emsp;{walletAddress}
              </>
            )}
          </CTNButton>
          <br />
          <br />
          <s.TextSubTitle style={{ textAlign: "center", fontSize: "1rem" }}>
            {feedback}
          </s.TextSubTitle>
        </ConnectDivM>

        <s.ContainerMain flex={1}>
          <>
            {/* =================================================================
=================================================================
========================111111111111=============================
=================================================================
================================================================= */}
            <ResponsiveWrapper flex={1}>
              {/* <s.SpacerSmall /> */}
              <s.Container flex={1} jc={"center"} ai={"center"}>
                {Number(data.totalSupply) >= CONFIG.MAX_BLACK_SUPPLY ? (
                  <>
                    <s.SpacerLarge />
                    <img
                      className="main-png"
                      alt={"png"}
                      src={"/config/images/MCMF_BLACK.gif"} ///none
                    />
                    <s.SpacerSmall />
                    <s.TextSubTitle
                      style={{
                        textAlign: "center",
                        fontSize: "2rem",
                        fontFamily: "Raleway",
                      }}
                    >
                      SOLD OUT!
                    </s.TextSubTitle>
                    <s.SpacerSmall />
                    <StyledLink href={CONFIG.MARKETPLACE_LINK}>
                      GO {CONFIG.MARKETPLACE}
                    </StyledLink>
                  </>
                ) : (
                  <>
                    <s.SpacerLarge />
                    <img
                      className="main-png"
                      alt={"png"}
                      src={"/config/images/MCMF_BLACK.gif"} ////One
                    />
                    <s.SpacerLarge />
                    <TireFont>BLACK</TireFont>
                    {blockchain.account === "" ||
                    blockchain.smartContract === null ? (
                      <>
                        <s.Container ai={"center"} jc={"center"}>
                          <s.SpacerSmall />
                          {blockchain.errorMsg !== "" ? (
                            <>
                              <s.SpacerSmall />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                  fontFamily: "Raleway",
                                  fontSize: 20,
                                }}
                              >
                                {blockchain.errorMsg}
                              </s.TextDescription>
                            </>
                          ) : null}
                        </s.Container>
                      </>
                    ) : (
                      <>
                        <s.SpacerSmall />

                        <s.TextSub
                          style={{
                            textAlign: "center",
                            color: "#728FA3",
                            fontFamily: "Anton",
                            fontWeight: "400",
                          }}
                        >
                          {data.totalSupply} | {CONFIG.MAX_BLACK_SUPPLY}
                        </s.TextSub>

                        {whitlisted == true ? (
                          <>
                            <s.SpacerSmall />
                            <s.AmountContainer
                              style={{
                                border: brd,
                                boxShadow: bxsh,
                              }}
                            >
                              <StyledRoundButton
                                style={{ lineHeight: 0.4 }}
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  decrementtokens();
                                }}
                              >
                                {/* <img
                                className="amountBTN"
                                alt={"png"}
                                src={"/config/images/-.png"}
                              /> */}
                                -
                              </StyledRoundButton>
                              <s.TEXTamount>
                                &ensp;&ensp;&ensp;{" "}
                                {Math.min(Math.max(1, tokens), 5)}{" "}
                                &ensp;&ensp;&ensp;
                              </s.TEXTamount>

                              {/* </div> */}
                            </s.AmountContainer>
                            <s.SpacerSmall />

                            {isMinted < 10 ? (
                              <>
                                <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"column"}
                                >
                                  <StyledButton
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      claimNFTs();
                                      getData();
                                    }}
                                  >
                                    {claimingNft ? (
                                      <Loader
                                        speed="fast"
                                        content="Minting..."
                                      />
                                    ) : (
                                      "MINT NFT"
                                    )}
                                  </StyledButton>
                                </s.Container>
                                <s.SpacerSmall />
                                <br />
                                <br />
                                <s.TextTotal
                                  style={{
                                    textAlign: "center",
                                    fontSize: 15,
                                    color: "#728FA3",
                                  }}
                                >
                                  Can mint{" "}
                                  <span style={{ color: "#FF69F9" }}>
                                    {10 - isMinted}
                                    {/* {mintCountBlack} */}
                                  </span>
                                  more
                                </s.TextTotal>
                              </>
                            ) : (
                              <>
                                <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"column"}
                                >
                                  <StyledButton
                                    disabled={isMinted >= 10}
                                    onClick={null}
                                  >
                                    {claimingNft ? (
                                      <Loader
                                        speed="fast"
                                        content="Minting..."
                                      />
                                    ) : (
                                      "MINTED"
                                    )}
                                  </StyledButton>
                                </s.Container>

                                <StyledLink href={CONFIG.COLLECTION_LINK}>
                                  GO {CONFIG.MARKETPLACE}
                                </StyledLink>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <s.SpacerSmall />
                            <s.AmountContainer
                              style={{
                                border: brd,
                                boxShadow: bxsh,
                              }}
                            >
                              <StyledRoundButton
                                style={{ lineHeight: 0.4 }}
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  decrementtokens();
                                }}
                              >
                                {/* <img
                                className="amountBTN"
                                alt={"png"}
                                src={"/config/images/-.png"}
                              /> */}
                                -
                              </StyledRoundButton>
                              <s.TEXTamount>
                                &ensp;&ensp;&ensp;{" "}
                                {Math.min(Math.max(1, tokens), 5)}{" "}
                                &ensp;&ensp;&ensp;
                              </s.TEXTamount>

                              <StyledRoundButton
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  incrementtokens();
                                }}
                              >
                                {/* <img
                                className="amountBTN"
                                alt={"png"}
                                src={"/config/images/+.png"}
                              /> */}
                                +
                              </StyledRoundButton>
                            </s.AmountContainer>
                            <s.SpacerSmall />

                            {isMinted < 10 ? (
                              <>
                                <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"column"}
                                >
                                  <StyledButton
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      claimNFTs();
                                      getData();
                                    }}
                                  >
                                    {claimingNft ? (
                                      <Loader
                                        speed="fast"
                                        content="Minting..."
                                      />
                                    ) : (
                                      "MINT NFT"
                                    )}
                                  </StyledButton>

                                  {/* <StyledButton
                                            disabled={whitlisted == false}
                                            onClick={null}
                                          >
                                            {claimingNft ? (
                                              <Loader speed="fast" content="Minting..." />
                                            ) : (
                                              "NOT WL"
                                            )}
                                          </StyledButton> */}
                                </s.Container>
                                <s.SpacerSmall />
                                <br />
                                <br />
                                <s.TextTotal
                                  style={{
                                    textAlign: "center",
                                    fontSize: 15,
                                    // color: "#728FA3",
                                    color: "#ffffff",
                                  }}
                                >
                                  Can mint{" "}
                                  <span style={{ color: "#FF69F9" }}>
                                    {10 - isMinted}
                                    {/* {mintCountBlack} */}
                                  </span>
                                  more
                                </s.TextTotal>
                              </>
                            ) : (
                              <>
                                <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"column"}
                                >
                                  <StyledButton
                                    disabled={isMinted >= 10}
                                    onClick={null}
                                  >
                                    {claimingNft ? (
                                      <Loader
                                        speed="fast"
                                        content="Minting..."
                                      />
                                    ) : (
                                      "MINTED"
                                    )}
                                  </StyledButton>
                                </s.Container>

                                <StyledLink href={CONFIG.COLLECTION_LINK}>
                                  GO {CONFIG.MARKETPLACE}
                                </StyledLink>
                              </>
                            )}
                          </>
                        )}
                        <s.SpacerSmall />
                        <s.SpacerXSmall />
                        <s.TextTotal
                          style={{
                            textAlign: "center",
                            fontSize: 15,
                            // color: "#728FA3",
                            color: "#ffffff",
                          }}
                        >
                          {/* <span style={{ color: "#FF69F9" }}>
                            {Math.round(
                              CONFIG.DISPLAY_BLACK_COST * tokens * 10
                            ) / 10}{" "}
                          </span> */}
                          <span style={{ color: "#FF69F9" }}>
                            {Math.round(
                              CONFIG.DISPLAY_BLACK_COST *
                                Math.min(tokens, 5) *
                                100
                            ) / 100}{" "}
                          </span>
                          ETH
                          <br /> Max{" "}
                          <span style={{ color: "#FF69F9" }}>
                            {CONFIG.MAX_PER_TX_BLACK}
                          </span>{" "}
                          Per Wallet
                          <br /> Max <span style={{ color: "#FF69F9" }}>
                            5
                          </span>{" "}
                          Per Tx
                        </s.TextTotal>
                        <s.SpacerXSmall />
                        {/* <s.TextSubTitle
                        style={{ textAlign: "center", fontSize: "1rem" }}
                      >
                        {feedback}
                      </s.TextSubTitle> */}
                      </>
                    )}
                  </>
                )}
                {/* <s.SpacerMedium /> */}
              </s.Container>
              {/* <s.SpacerLarge /> */}
            </ResponsiveWrapper>

            {/* =================================================================
=================================================================
========================111111111111=============================
=================================================================
================================================================= */}

            {/* =================================================================
=================================================================
========================222222222=============================
=================================================================
================================================================= */}
            <ResponsiveWrapper flex={1}>
              {/* <s.SpacerSmall /> */}
              <s.Container flex={1} jc={"center"} ai={"center"}>
                {Number(data.totalSupply) >= CONFIG.MAX_Platinum_SUPPLY ? (
                  <>
                    <s.SpacerLarge />
                    <img
                      className="main-png"
                      alt={"png"}
                      src={"/config/images/MCMF_Platinum.gif"} ///none
                    />

                    <s.SpacerSmall />
                    <s.TextSubTitle
                      style={{ textAlign: "center", fontSize: "2rem" }}
                    >
                      SOLD OUT!
                    </s.TextSubTitle>
                    <s.SpacerSmall />
                    <StyledLink href={CONFIG.MARKETPLACE_LINK}>
                      GO {CONFIG.MARKETPLACE}
                    </StyledLink>
                  </>
                ) : (
                  <>
                    <s.SpacerLarge />
                    <img
                      className="main-png"
                      alt={"png"}
                      src={"/config/images/MCMF_Platinum.gif"} ///Two
                    />
                    <s.SpacerLarge />
                    <TireFont>Platinum</TireFont>
                    {blockchain.account === "" ||
                    blockchain.smartContract === null ? (
                      <>
                        <s.Container ai={"center"} jc={"center"}>
                          <s.SpacerSmall />
                          {blockchain.errorMsg !== "" ? (
                            <>
                              <s.SpacerSmall />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                  fontFamily: "Raleway",
                                  fontSize: 20,
                                }}
                              >
                                {blockchain.errorMsg}
                              </s.TextDescription>
                            </>
                          ) : null}
                        </s.Container>
                      </>
                    ) : (
                      <>
                        <s.SpacerSmall />

                        <s.TextSub
                          style={{
                            textAlign: "center",
                            color: "#728FA3",
                            fontFamily: "Anton",
                          }}
                        >
                          {data.totalSupplyPlatinum} |{" "}
                          {CONFIG.MAX_Platinum_SUPPLY}
                        </s.TextSub>

                        {whitlisted == true ? (
                          <>
                            <s.SpacerSmall />
                            <s.AmountContainer
                              style={{
                                border: brd,
                                boxShadow: bxsh,
                              }}
                            >
                              <StyledRoundButton
                                style={{ lineHeight: 0.4 }}
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  decrementtokensPlatinum();
                                }}
                              >
                                -
                              </StyledRoundButton>

                              <s.TEXTamount>
                                &ensp;&ensp;&ensp;{" "}
                                {Math.min(Math.max(1, tokensPlatinum), 5)}{" "}
                                &ensp;&ensp;&ensp;
                              </s.TEXTamount>

                              <StyledRoundButton
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  incrementtokensPlatinum();
                                }}
                              >
                                +
                              </StyledRoundButton>
                              {/* </div> */}
                            </s.AmountContainer>
                            <s.SpacerSmall />

                            {isMintedPlatinum < 20 ? (
                              <>
                                <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"column"}
                                >
                                  <StyledButton
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      claimNFTsPlatinum();
                                      getData();
                                    }}
                                  >
                                    {claimingNft ? (
                                      <Loader
                                        speed="fast"
                                        content="Minting..."
                                      />
                                    ) : (
                                      "MINT NFT"
                                    )}
                                  </StyledButton>
                                </s.Container>
                                <s.SpacerSmall />
                                <br />
                                <br />
                                <s.TextTotal
                                  style={{
                                    textAlign: "center",
                                    fontSize: 15,
                                    // color: "#728FA3",
                                    color: "#ffffff",
                                  }}
                                >
                                  Can mint{" "}
                                  <span style={{ color: "#FF69F9" }}>
                                    {20 - isMintedPlatinum}
                                    {/* {mintCountPlatinum} */}
                                  </span>
                                  more
                                </s.TextTotal>
                              </>
                            ) : (
                              <>
                                <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"column"}
                                >
                                  <StyledButton
                                    disabled={isMintedPlatinum >= 20}
                                    onClick={null}
                                  >
                                    {claimingNft ? (
                                      <Loader
                                        speed="fast"
                                        content="Minting..."
                                      />
                                    ) : (
                                      "MINTED"
                                    )}
                                  </StyledButton>
                                </s.Container>

                                <StyledLink href={CONFIG.COLLECTION_LINK}>
                                  GO {CONFIG.MARKETPLACE}
                                </StyledLink>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <s.SpacerSmall />
                            <s.AmountContainer
                              style={{
                                border: brd,
                                boxShadow: bxsh,
                              }}
                            >
                              <StyledRoundButton
                                style={{ lineHeight: 0.4 }}
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  decrementtokensPlatinum();
                                }}
                              >
                                {/* <img
                                className="amountBTN"
                                alt={"png"}
                                src={"/config/images/-.png"}
                              /> */}
                                -
                              </StyledRoundButton>
                              <s.TEXTamount>
                                &ensp;&ensp;&ensp;{" "}
                                {Math.min(Math.max(1, tokensPlatinum), 5)}{" "}
                                &ensp;&ensp;&ensp;
                              </s.TEXTamount>

                              <StyledRoundButton
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  incrementtokensPlatinum();
                                }}
                              >
                                {/* <img
                                className="amountBTN"
                                alt={"png"}
                                src={"/config/images/+.png"}
                              /> */}
                                +
                              </StyledRoundButton>
                            </s.AmountContainer>
                            <s.SpacerSmall />

                            {isMintedPlatinum < 20 ? (
                              <>
                                <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"column"}
                                >
                                  <StyledButton
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      claimNFTsPlatinum();
                                      getData();
                                    }}
                                  >
                                    {claimingNft ? (
                                      <Loader
                                        speed="fast"
                                        content="Minting..."
                                      />
                                    ) : (
                                      "MINT NFT"
                                    )}
                                  </StyledButton>

                                  {/* <StyledButton
                                            disabled={whitlisted == false}
                                            onClick={null}
                                          >
                                            {claimingNft ? (
                                              <Loader speed="fast" content="Minting..." />
                                            ) : (
                                              "NOT WL"
                                            )}
                                          </StyledButton> */}
                                </s.Container>
                                <s.SpacerSmall />
                                <br />
                                <br />
                                <s.TextTotal
                                  style={{
                                    textAlign: "center",
                                    fontSize: 15,
                                    // color: "#728FA3",
                                    color: "#ffffff",
                                  }}
                                >
                                  Can mint{" "}
                                  <span style={{ color: "#FF69F9" }}>
                                    {20 - isMintedPlatinum}
                                    {/* {mintCountPlatinum} */}
                                  </span>
                                  more
                                </s.TextTotal>
                              </>
                            ) : (
                              <>
                                <s.Container
                                  ai={"center"}
                                  jc={"center"}
                                  fd={"column"}
                                >
                                  <StyledButton
                                    disabled={isMintedPlatinum >= 20}
                                    onClick={null}
                                  >
                                    {claimingNft ? (
                                      <Loader
                                        speed="fast"
                                        content="Minting..."
                                      />
                                    ) : (
                                      "MINTED"
                                    )}
                                  </StyledButton>
                                </s.Container>
                                <br />
                                <br />
                                <StyledLink href={CONFIG.COLLECTION_LINK}>
                                  GO {CONFIG.MARKETPLACE}
                                </StyledLink>
                              </>
                            )}
                          </>
                        )}
                        <s.SpacerSmall />
                        <s.SpacerXSmall />
                        <s.TextTotal
                          style={{
                            textAlign: "center",
                            fontSize: 15,
                            // color: "#728FA3",
                            color: "#ffffff",
                          }}
                        >
                          {/* <span style={{ color: "#FF69F9" }}>
                            {Math.round(
                              CONFIG.DISPLAY_Platinum_COST * tokensPlatinum * 10
                            ) / 10}{" "}
                          </span> */}
                          <span style={{ color: "#FF69F9" }}>
                            {Math.round(
                              CONFIG.DISPLAY_Platinum_COST *
                                Math.min(tokensPlatinum, 5) *
                                100
                            ) / 100}{" "}
                          </span>
                          ETH
                          <br /> Max{" "}
                          <span style={{ color: "#FF69F9" }}>
                            {CONFIG.MAX_PER_TX_Platinum}
                          </span>{" "}
                          Per Wallet
                          <br /> Max <span style={{ color: "#FF69F9" }}>
                            5
                          </span>{" "}
                          Per Tx
                        </s.TextTotal>
                        <s.SpacerXSmall />
                        {/* <s.TextSubTitle
                        style={{ textAlign: "center", fontSize: "1rem" }}
                      >
                        {feedback}
                      </s.TextSubTitle> */}
                      </>
                    )}
                  </>
                )}
                {/* <s.SpacerMedium /> */}
              </s.Container>
              {/* <s.SpacerLarge /> */}
            </ResponsiveWrapper>

            {/* =================================================================
=================================================================
========================222222222=============================
=================================================================
================================================================= */}
          </>
        </s.ContainerMain>

        <ConnectDiv>
          <CTNButton
            onClick={(e) => {
              e.preventDefault();
              dispatch(connect());
              getData();
            }}
          >
            {blockchain.account === "" || blockchain.smartContract === null ? (
              <>Connect Wallet</>
            ) : (
              <>
                {DOT}&emsp;{walletAddress}
              </>
            )}
          </CTNButton>
          <br />
          <br />
          <s.TextSubTitle style={{ textAlign: "center", fontSize: "1rem" }}>
            {feedback}
          </s.TextSubTitle>
        </ConnectDiv>
      </s.Screen>
    </section>
  );
}

export default App;
