import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import "../index.css";

export const BuildBack = styled.div`
  background: linear-gradient(
    to bottom,
    // transparent,
    black 25%,
    black 75%,
    transparent
  );

  width: 100%;
  height: inherit;
  align-content: flex-start;
  justify-content: center;
  display: flex;
  align-items: center;
`;
export const BuildSec = styled.div`
  background-color: black;
`;

export const BuildText = styled.p`
  font-family: "Raleway";
  font-weight: 400;
  padding: 10px;
  padding-bottom: 50px;
  font-size: 5rem;
  color: white;

  @media only screen and (max-width: 425px) {
    font-size: 3rem;
  }
`;

export const Build = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;

  box-sizing: border-box;
`;

export const BuildBox = styled.div`
  max-width: 416px;
  min-width: 277px;
  color: #000;
  background-color: #ff9898;
  border-radius: 40px;
  flex-flow: column wrap;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 32px;
  margin-left: 16px;
  margin-right: 16px;
  padding: 64px 36px 36px;
  display: flex;
  position: relative;
  overflow: auto;

  @media only screen and (max-width: 425px) {
    max-width: 90%;
    min-width: 250px;
    margin-left: 1px;
    margin-right: 1px;
  }
`;
export const BuildBox2 = styled.div`
  max-width: 416px;
  min-width: 277px;
  color: #000;
  background-color: #3498db;
  border-radius: 40px;
  flex-flow: column wrap;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 32px;
  margin-left: 16px;
  margin-right: 16px;
  padding: 64px 36px 36px;
  display: flex;
  position: relative;
  overflow: auto;

  @media only screen and (max-width: 425px) {
    max-width: 90%;
    min-width: 250px;
    margin-left: 1px;
    margin-right: 1px;
  }
`;
export const BuildBox3 = styled.div`
  max-width: 416px;
  min-width: 277px;
  color: #000;
  background-color: #8459ff;
  border-radius: 40px;
  flex-flow: column wrap;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 32px;
  margin-left: 16px;
  margin-right: 16px;
  padding: 64px 36px 36px;
  display: flex;
  position: relative;
  overflow: auto;

  @media only screen and (max-width: 425px) {
    max-width: 90%;
    min-width: 250px;
    margin-left: 1px;
    margin-right: 1px;
  }
`;

export const BuildBoxInner = styled.div`
  text-align: left;
  z-index: 1;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
`;

export const BuildTextH1 = styled.h1`
  color: white;
  font-family: "Raleway";
  font-size: 40px;

  margin-bottom: 24px;
`;

export const BuildTextP = styled.p`
  font-family: "Raleway";
  font-size: 16px;
  white-space: break-word;
  line-height: 30px;
`;

export const BuildButton = styled.a`
  color: #fff;
  text-align: center;
  background-color: #000;
  border: 0 transparent;
  border-radius: 100px;
  margin-left: 0;
  margin-right: 0;
  padding: 16px 32px;
  font-size: 20px;
  font-weight: 500;

  text-decoration-line: none;
  cursor: pointer;

  &:hover {
    text-decoration-line: none;
    color: white;
    background-color: rgb(39, 39, 39);
  }
`;

export const BuildTextSec = styled.div`
  margin-bottom: 32px;
`;

export const Buildsecbox = styled.div`
  height: inherit;
  // padding-top: -50px;

  @media (max-width: 900px) {
    padding-top: 200px;
  }

  @media (max-width: 425px) {
    // padding-top: -350px;
  }
`;

const Section6 = () => {
  return (
    <section id="Build">
      <BuildBack>
        <Buildsecbox>
          <BuildText>Let’s Build it together</BuildText>
          <Build>
            <BuildBox>
              <BuildBoxInner>
                <BuildTextSec>
                  <BuildTextH1>Volunteerism</BuildTextH1>
                  <BuildTextP>
                    We actively promote and participate in various volunteering
                    opportunities, aiming to make a tangible impact in our
                    communities. Our members are encouraged to contribute their
                    time and skills to support those in need, fostering a spirit
                    of service and cooperation.
                  </BuildTextP>
                </BuildTextSec>
                {/* <BuildButton>Jump In</BuildButton> */}
              </BuildBoxInner>
            </BuildBox>

            <BuildBox2>
              <BuildBoxInner>
                <BuildTextSec>
                  <BuildTextH1>Donations</BuildTextH1>
                  <BuildTextP>
                    Our foundation places a strong emphasis on channeling
                    donations directly to initiatives and programs that benefit
                    children. We are committed to providing resources and
                    support to underprivileged young individuals, with the goal
                    of creating a nurturing environment that fosters their
                    growth and development towards a brighter future.
                  </BuildTextP>
                </BuildTextSec>
                {/* <BuildButton>Join Our Cause</BuildButton> */}
              </BuildBoxInner>
            </BuildBox2>

            <BuildBox3>
              <BuildBoxInner>
                <BuildTextSec>
                  <BuildTextH1>Community</BuildTextH1>
                  <BuildTextP>
                    Open and transparent communication is at the heart of our
                    foundation. We prioritize creating a dialog with our
                    members, partners, and the communities we serve, ensuring
                    that every voice is heard and valued. Through effective
                    communication, we strengthen our bonds and work
                    collaboratively towards our common goals.
                  </BuildTextP>
                </BuildTextSec>
                {/* <BuildButton>Build it together</BuildButton> */}
              </BuildBoxInner>
            </BuildBox3>
          </Build>
        </Buildsecbox>
      </BuildBack>
    </section>
  );
};

export default Section6;
