import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { FaDiscord, FaTwitter, FaOpencritic, FaBars } from "react-icons/fa";
import { SiOpensea } from "react-icons/si";
import MCMF from "./components/MCMF";

export const LOGOText = styled.h1`
  font-family: "Bungee";
  color: black;

  font-size: 5rem;
  display: flex;
  align-items: baseline;

  text-align: center;
  margin: 0 auto;

  @media (max-width: 425px) {
    font-size: 2rem;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
`;
export const LOGOTextB = styled.h1`
  font-family: "Raleway";
  color: black;
  padding: 5px;
  font-size: 1rem;
  display: flex;
  align-items: baseline;

  @media (max-width: 425px) {
    font-size: 0.4rem;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
`;

export const LOGOTextS = styled.h1`
  font-family: "Bungee";
  color: black;
  padding: 5px;
  font-size: 12px;
  display: flex;
  align-items: baseline;
`;

export const TestText = styled.h1`
  font-family: "Raleway";
  font-weight: 400;
  font-size: 2rem;
  padding: 10px;
  color: black;
`;
export const TestLogo = styled.div`
  display: block;
  padding: 10px;
  width: 100%;
  text-align: center;
  align-items: baseline;
  margin: 0 auto;
  justify-content: center;
`;
export const FooterDiv = styled.div`
  color: black;
`;

export const FooterDivInner = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 50px;
  text-align: center;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  gap: 50px;

  @media only screen and (max-width: 599px) {
    display: block;
  }
`;

export const FooterSocal = styled.div`
  display: contents;
  justify-content: center;

  width: 100%;
  margin: 0 auto;
  text-align: center;

  @media only screen and (max-width: 599px) {
    width: auto;
    display: flex;
    margin: 15px;
  }
`;

export const FooterUl = styled.ul`
  text-align: center;
  margin: 0 auto;
  list-style: none;
`;

export const FooterliD = styled.li`
  float: left;
  width: 30px;
  cursor: pointer;
  color: black;

  &:hover {
    color: #7289da;
  }
`;
export const FooterliT = styled.li`
  float: left;
  width: 30px;
  cursor: pointer;
  color: black;

  &:hover {
    color: #1da1f2;
  }
`;
export const FooterliO = styled.li`
  float: left;
  width: 30px;
  cursor: pointer;
  color: black;

  &:hover {
    color: #2081e2;
  }
`;

export const Container = styled.div`
  color: black;
  border: 2px solid rgba(0, 0, 0, 0.23);
  border-radius: 7px;

  @media only screen and (max-width: 599px) {
    margin-top: 15px;
  }
`;
export const Submit = styled.button`
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  height: 40px;
  width: 140px;

  cursor: not-allowed;
`;
export const FooterAClass = styled.a`
  font-family: "Raleway";
  font-size: 12px;
  cursor: pointer;
  color: black;
  line-height: 25px;

  &:hover {
    color: black;
  }
`;

const Footer = () => {
  return (
    <footer id="Footer">
      <FooterDiv>
        {/* <TestText>Stay Connected</TestText>
        <FooterDivInner>
          <p style={{ color: "black", fontFamily: "Raleway" }}>Contact Us</p>
          
          <Container>
            <input
              type="text"
              className="inputField"
              // placeholder="Email address"
              placeholder="Preparing!"
            />
            <Submit>Submit</Submit>
          </Container>

          <FooterSocal>
            <FooterUl>
              <FooterliD>
                <FaDiscord size={20} />
              </FooterliD>
              <FooterliT>
                <FaTwitter size={20} />
              </FooterliT>
              <FooterliO>
                <SiOpensea size={20} />
              </FooterliO>
            </FooterUl>
          </FooterSocal>
        </FooterDivInner> */}
        <TestLogo>
          <LOGOText>MCMF</LOGOText>
          <LOGOTextB>Miss Change Makers Foundation</LOGOTextB>
        </TestLogo>
        <FooterDivInner>
          <FooterSocal>
            <FooterUl>
              <FooterliD>
                <FaDiscord size={20} />
              </FooterliD>
              <FooterliT>
                <FaTwitter size={20} />
              </FooterliT>
              <FooterliO>
                <SiOpensea size={20} />
              </FooterliO>
            </FooterUl>
          </FooterSocal>
        </FooterDivInner>
        <a
          style={{
            display: "flex",
            alignItems: "baseline",
            textAlign: "center",
            justifyContent: "center",
            color: "black",
            lineHeight: "25px",
            fontFamily: "Raleway",
            fontSize: "12px",
          }}
        >
          Copyright © 2023<LOGOTextS>MCMF</LOGOTextS>
        </a>
        <FooterAClass>Terms of Service</FooterAClass> ❋{" "}
        <FooterAClass>Privacy Policy</FooterAClass>
      </FooterDiv>
    </footer>
  );
};

export default Footer;
