import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

export const TestText = styled.p`
  font-family: "boorsok";
  padding: 10px;
  color: black;
`;

const Section3 = () => {
  return (
    <section id="Contact">
      <TestText>WHAT IS TEDDY BEAR MIRACLE WORLD?</TestText>
    </section>
  );
};

export default Section3;
