import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import MCMF from "./components/MCMF";
import MissImg from "./img/Miss.png";
export const ProjectSec = styled.div`
  display: flex;

  padding-top: 100px;
  padding-bottom: 50px;

  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
`;

export const ProjectTextSec = styled.div`
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 64px;
  padding-right: 64px;
`;

export const ProjectsH1 = styled.h1`
  text-size: 5rem;
`;

export const ProjectTextP = styled.p`
  max-width: 520px;
  margin-bottom: 96px;
  margin-left: 16%;
  text-align: left;
  font-family: "Raleway";
  font-size: 22px;
`;

export const ProjectTextDiv = styled.div`
  margin-bottom: 64px;
  display: flex;
  font-family: "Raleway";
  font-weight: 400;
  font-size: 4rem;
  align-items: baseline;
`;

export const ProjectsImage = styled.img`
  width: 50%;
  max-width: 600px;
  float: right;
  margin: -96px -92px 96px 24px;
`;

const Section4 = () => {
  return (
    <section id="Projects">
      <ProjectSec>
        {/* <ProjectTextSec>
          <ProjectsImage
            // src="https://uploads-ssl.webflow.com/6233b4e039fbe3281ef62943/6260d37e351c232030994856_illustration-1.png"
            src={MissImg}
            loading="lazy"
            sizes="(max-width: 479px) 400px, (max-width: 767px) 46vw, (max-width: 991px) 47vw, (max-width: 1439px) 44vw, 46vw"
            srcset="https://uploads-ssl.webflow.com/6233b4e039fbe3281ef62943/6260d37e351c232030994856_illustration-1-p-500.png 500w, https://uploads-ssl.webflow.com/6233b4e039fbe3281ef62943/6260d37e351c232030994856_illustration-1.png 1080w"
            alt="Artwork by Jade Purple Brown."
            class="image-enter-bff-illustration"
          />
          <ProjectTextDiv>
            Enter&nbsp;
            <MCMF />
          </ProjectTextDiv>
          <ProjectTextP>
            Started by 100+ leaders across industries, BFF is an open-access
            community with a mission to help women and non-binary people get
            educated, connected, and empowered in web3.
            <br />
            <br />
            We aim to create one of the largest decentralized brands on the
            Internet.
          </ProjectTextP>
          <ProjectsImage />
        </ProjectTextSec> */}
      </ProjectSec>
    </section>
  );
};

export default Section4;
