import { createGlobalStyle } from "styled-components";
import RenoMono from "./fonts/RenoMono.otf";
import Upheaval from "./fonts/upheavtt.ttf";
import PlayfairDisplay from "./fonts/PlayfairDisplay/PlayfairDisplaySC-Regular.ttf"; //제목
import Raleway from "./fonts/Raleway/Raleway-VariableFont_wght.ttf"; //본문
import Anton from "./fonts/Anton/Anton-Regular.ttf"; //로고
import Bungee from "./fonts/Bungee/Bungee-Regular.ttf"; //로고
// import Gumball from './Gumball.ttf';

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Upheaval';
        src: url(${Upheaval}) format('truetype');
    }

    @font-face {
        font-family: 'Renomono';
        src: url(${RenoMono}) format('opentype');
    }

    @font-face {
        font-family: 'PlayfairDisplay';
        src: url(${PlayfairDisplay}) format('opentype');
    }
    
    @font-face { 
        font-family: 'Raleway';
        src: url(${Raleway}) format('opentype');
    }

    @font-face { 
        font-family: 'Anton';
        src: url(${Anton}) format('opentype');
    }

    @font-face { 
        font-family: 'Bungee';
        src: url(${Bungee}) format('opentype');
    }
`;

// export const Renomono = createGlobalStyle`
//     @font-face {
//         font-family: 'Renomono';
//         src: url(${RenoMono}) format('opentype');
//     }
// `;

export default GlobalStyle;
