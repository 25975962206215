import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import "../index.css";

const PartnersSec = styled.div`
  display: block;
`;
const PartnersTxt = styled.p`
  color: black;
  font-family: "Raleway";
  font-weight: 400;
  padding: 10px;
  padding-bottom: 50px;
  font-size: 5rem;

  @media only screen and (max-width: 425px) {
    font-size: 3rem;
  }
`;
const PartnersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
`;
const Logo = styled.img`
  max-width: 150px;
  height: auto;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const partners = [
  { id: 1, src: "logos/Beauty-Hankook.png", alt: "Beauty-Hankook" },
  { id: 2, src: "logos/Mikoleaders.png", alt: "Mikoleaders" },
  { id: 3, src: "logos/Air-Travi.png", alt: "Air-Travi" },
  { id: 4, src: "logos/BackersBy.svg", alt: "BackersBy" },
  { id: 5, src: "logos/TPX_Black.png", alt: "TPX" },
];

const Partners = () => {
  return (
    <section id="Partners">
      <PartnersSec>
        <PartnersTxt>Our Partners</PartnersTxt>
        <PartnersContainer>
          {partners.map((partner) => (
            <a key={partner.id}>
              {" "}
              <Logo src={partner.src} alt={partner.alt} />
            </a>
          ))}
        </PartnersContainer>
      </PartnersSec>
    </section>
  );
};

export default Partners;
