import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Canvas from "./components/Canvas";
import { Link } from "react-scroll";
// import { Link as RouterLink } from "react-router-dom";

export const HomeSec = styled.div`
  display: block;

  @media only screen and (max-width: 899px) {
  }
`;

export const HomeMain = styled.div`
  display: flex;

  @media only screen and (max-width: 899px) {
    display: block;
    // padding-top: 80px;
  }
`;

export const HomeMainText = styled.p`
  font-family: "Raleway";
  font-weight: 400;
  padding: 10px;
  color: white;
  font-size: 3rem;

  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
`;

export const HomeSpan = styled.span`
  color: rosybrown;
`;

export const CanvasSec = styled.div`
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media only screen and (max-width: 425px) {
  }
`;

export const HomeBtn = styled.button`
  color: #000;
  text-align: center;
  background-color: #fff;
  border-radius: 100px;
  padding: 16px 32px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: #000;
    background-color: #fec750;
    border-width: 0;
    text-decoration-line: none;
  }
`;
export const HomeTxt = styled.p`
  font-family: "Raleway";
  color: white;
  font-size: 1rem;
  margin-bottom: 50px;
`;

const Navbar = ({ navClass, linkClassName }) => (
  <NavComponent navClass={navClass} linkClassName={linkClassName} />
);

const Section1 = ({ onClick, navClass, linkClassName }) => {
  return (
    <section id="Home">
      <HomeSec>
        <HomeMain>
          <HomeMainText>
            Miss Change Makers
            <br />
            Foundation
          </HomeMainText>
          <CanvasSec>
            <Canvas />
          </CanvasSec>
        </HomeMain>
        <br />
        <HomeTxt>‘Empowering Women, Enabling Change’</HomeTxt>
        <Link
          to="Build"
          smooth={true}
          className={linkClassName}
          onClick={onClick}
        >
          <HomeBtn>Build together</HomeBtn>
        </Link>
      </HomeSec>
    </section>
  );
};

export default Section1;
