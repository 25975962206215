import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

export const AboutSec = styled.div`
  max-width: 100%;
  flex-direction: column;
  padding: 96px 64px;
  display: block;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    padding: 150px 25px;
  }
`;
export const AboutText = styled.p`
  font-family: "Raleway";
  padding: 10px;
  color: white;
`;
export const IntroSecText = styled.div`
  margin-bottom: 64px;
`;
export const IntroSecH1 = styled.p`
  font-family: "Raleway";

  text-align: center;
  margin-top: 0;
  letter-spacing: 0;
  margin-top: 0;
  margin-bottom: 0;
  // font-family: PP Right Grotesk Spatial Fine, sans-serif;
  font-size: 5rem;
  font-weight: 400;
  line-height: 90%;

  @media only screen and (max-width: 768px) {
    font-size: 3rem;
  }
`;
export const IntroTextSec = styled.div`
  flex-wrap: wrap;
  justify-content: center;
  margin-right: -16px;
  display: flex;

  @media only screen and (max-width: 899px) {
    display: block;
    margin-right: 0px;
  }
`;
export const IntroSecPLeft = styled.p`
  max-width: 520px;
  flex: 1;
  margin-bottom: 0;
  margin-right: 16px;
  font-size: 22px;
  text-align: left;
  font-family: "Raleway";

  @media only screen and (max-width: 899px) {
    padding-bottom: 50px;
    text-align: center;
    display: inline-grid;

    margin: 0 auto;
  }
`;
export const IntroSecA = styled.a`
  color: #fec750;
  font-weight: 800;
  cursor: auto;
`;
export const IntroSecRBox = styled.div`
  max-width: 400px;
  border: 1px solid #fff;
  border-radius: 48px;
  flex-direction: column;
  flex: 1;
  align-self: flex-start;
  align-items: center;
  margin-left: 16px;
  padding: 32px;
  display: flex;

  @media only screen and (max-width: 899px) {
    margin: 0 auto;
  }
`;
export const IntroSecBox1 = styled.div`
  margin-bottom: 12px;
  // font-family: PP Right Grotesk Tall Normal, sans-serif;
  font-family: "Raleway";
  font-size: 3rem;
  line-height: 90%;
`;
export const IntroSecBox2 = styled.div`
  text-align: center;
  letter-spacing: 1px;
  // font-family: Owners, sans-serif;
  font-family: "Raleway";
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
`;

export const IntroSecRBoxText = styled.p`
  max-width: 520px;
  flex: 1;
  margin-bottom: 0;
  margin-right: 16px;
  font-size: 16px;
  text-align: left;
  font-family: "Raleway";

  @media only screen and (max-width: 899px) {
    padding-bottom: 0px;
    text-align: center;
    display: inline-grid;

    margin: 0 auto;
  }
`;

const Section2 = () => {
  return (
    <section id="About">
      <AboutSec>
        <IntroSecText>
          <IntroSecH1>Discover Our Journey</IntroSecH1>
        </IntroSecText>

        <IntroTextSec>
          <IntroSecPLeft>
            {/* Our Mission: <br /> */}
            Embracing the decentralized ethos of Web3, the{" "}
            <IntroSecA>Miss Change Makers Foundation</IntroSecA> stands at the
            forefront of a new era, committed to venerating and elevating the
            roles and values of women. Established with the profound purpose of
            nurturing their potential, we aim to cultivate future leaders who
            will drive positive change.
            <br />
            <br />
            With an unwavering dedication, we provide women with boundless
            opportunities and the requisite support to transform their
            aspirations into reality. We underscore the significance of social
            engagement and philanthropy, paving the way for women to make
            substantial contributions at both the local and global levels.
            <br />
            <br />
            In our quest for innovation, we fortify women's rights and enhance
            social participation, all while preparing to pioneer the future with
            grace and resilience.
            {/* <br />
            <br />
            <IntroSecA href="/" target="_blank">
              More
            </IntroSecA> */}
          </IntroSecPLeft>
          <IntroSecRBox>
            <IntroSecBox1>Especially.{/* Specifically */}</IntroSecBox1>
            <IntroSecRBoxText>
              We are a foundation formed by an assembly of influential women,
              including those with notable social status, influencers, and
              former Miss Korea titleholders. Our mission is to create a
              brighter future for children, which we actively pursue through a
              range of philanthropic events and supportive endeavors.
            </IntroSecRBoxText>
            <a></a>
          </IntroSecRBox>
        </IntroTextSec>
      </AboutSec>
    </section>
  );
};

export default Section2;
