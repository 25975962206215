import React, { useRef, useEffect } from "react";
import "./Canvas.css";

const MAX = 50;

const MyCanvas = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Canvas 크기 설정 함수

    // const updateCanvasSize = () => {
    //   canvas.width = canvas.height =
    //     Math.min(window.innerWidth, window.innerHeight * 0.6) + 20; // 40은 margin을 고려한 값으로 필요에 따라 조정 가능
    //   // canvas.height = Math.min(window.innerWidth, window.innerHeight) - 0; // 40은 margin을 고려한 값으로 필요에 따라 조정 가능
    //   ctx.fillRect(0, 0, canvas.width, canvas.height);
    // };

    const updateCanvasSize = () => {
      if (window.innerWidth <= 425) {
        // 425px 이하일 때의 크기 설정
        canvas.width = canvas.height =
          Math.min(window.innerWidth, window.innerHeight * 0.6) + 30;
      } else {
        canvas.width = canvas.height =
          Math.min(window.innerWidth, window.innerHeight * 0.55) + 20;
      }
      if (window.innerWidth <= 425) {
        ctx.fillRect(-10, -10, canvas.width, canvas.height);
      } else {
        ctx.fillRect(0, 0, canvas.width, canvas.height);
      }
    };

    // 초기 설정
    updateCanvasSize();

    // resize 이벤트 리스너 추가
    window.addEventListener("resize", updateCanvasSize);

    let count = 0;
    const points = [];

    var r = 0;
    for (var a = 0; a < MAX; a++) {
      points.push([Math.cos(r), Math.sin(r), 0]);
      r += (Math.PI * 2) / MAX;
    }

    for (let a = 0; a < MAX; a++) {
      points.push([0, points[a][0], points[a][1]]);
    }

    for (let a = 0; a < MAX; a++) {
      points.push([points[a][1], 0, points[a][0]]);
    }

    function rus() {
      ctx.globalCompositeOperation = "source-over";
      ctx.fillStyle = "rgba(0,0,0,0.03)";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.globalCompositeOperation = "lighter";

      var tim = count / 5;
      for (var e = 0; e < 3; e++) {
        tim *= 1.7;
        var s = 1 - e / 3;
        let a = tim / 59;
        var yp = Math.cos(a);
        var yp2 = Math.sin(a);
        a = tim / 23;
        var xp = Math.cos(a);
        var xp2 = Math.sin(a);
        var p2 = [];

        for (let a = 0; a < points.length; a++) {
          var x = points[a][0];
          var y = points[a][1];
          var z = points[a][2];

          var y1 = y * yp + z * yp2;
          var z1 = y * yp2 - z * yp;
          var x1 = x * xp + z1 * xp2;

          z = x * xp2 - z1 * xp;
          z1 = Math.pow(2, z * s);
          x = x1 * z1;
          y = y1 * z1;
          p2.push([x, y, z]);
        }

        s *= 120;
        for (var d = 0; d < 3; d++) {
          for (let i = 0; i < MAX; i++) {
            const b = p2[d * MAX + i];
            const c = p2[((i + 1) % MAX) + d * MAX];
            ctx.beginPath();
            ctx.strokeStyle =
              "hsla(" + (((i / MAX) * 360) | 0) + ",70%,60%,0.15)";
            ctx.lineWidth = Math.pow(6, b[2]);
            ctx.lineTo(b[0] * s + 200, b[1] * s + 200);
            ctx.lineTo(c[0] * s + 200, c[1] * s + 200);
            ctx.stroke();
          }
        }
      }
      count++;
      requestAnimationFrame(rus);
    }
    rus();

    return () => {
      // 컴포넌트 언마운트 시 실행되는 클린업 로직
      window.removeEventListener("resize", updateCanvasSize);
    };
  }, []);

  return <canvas ref={canvasRef}></canvas>;
};

export default MyCanvas;
