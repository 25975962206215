import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import "../index.css";

export const Roadmap = styled.div`
  width: 90%;
`;
export const RoadmapTextSec = styled.div`
  margin-bottom: 30px;
  padding-bottom: 100px;
`;
export const RoadmapText1 = styled.div`
  font-size: 5rem;
  text-align: left;
  padding: 10px;
  color: black;
  font-family: "Raleway";
  font-weight: 400;
  display: flex;
  @media only screen and (max-width: 899px) {
    text-align: center;
    font-size: 3rem;
  }
`;
export const RoadmapText = styled.p`
  font-family: "Raleway";
  font-weight: 400;
  font-size: 5rem;
  text-align: left;
  padding: 10px;
  color: black;

  @media only screen and (max-width: 899px) {
    text-align: center;
    font-size: 3rem;
  }
`;
export const STAGEText = styled.div`
  width: 30%;
  text-align: center;

  @media only screen and (max-width: 899px) {
    width: 100%;
  }
`;
export const STAGESpan = styled.span`
  font-size: 4rem;
  font-family: PP Right Grotesk Tall Normal, sans-serif;
  color: black;
  font-family: "Raleway";
  @media only screen and (max-width: 899px) {
    font-size: 3rem;
  }
`;
export const STAGEP = styled.p`
  font-size: 24px;
  line-height: 27px;
  margin-bottom: 200px;
  // font-family: PP Right Grotesk Wide Medium, sans-serif;
  font-family: "Raleway";
  color: black;

  @media only screen and (max-width: 899px) {
    margin-bottom: 0px;
    font-size: 18px;
  }
`;
// ============
export const STAGE = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;

  @media only screen and (max-width: 899px) {
    flex-wrap: wrap;
    justify-content: center;
    display: block;
  }
`;
export const DateDiv = styled.div`
  min-width: 220px;
  text-align: center;
  margin-left: 32px;
  margin-right: 32px;
  position: relative;

  @media only screen and (max-width: 899px) {
    min-width: 220px;
    justify-content: center;
    align-items: flex-start;
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
  }
`;
export const DateSec = styled.div`
  justify-content: center;
  display: flex;
`;
export const DateText = styled.div`
  will-change: background;
  background-color: rgb(254, 199, 80);
  border-color: rgba(0, 0, 0, 0);
  color: black;

  border-radius: 100px;
  z-index: 1;
  flex: 1;
  padding: 16px 24px;
  // font-family: PP Right Grotesk Wide Medium, sans-serif;
  font-family: "Raleway";
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  position: relative;

  display: block;

  @media only screen and (max-width: 899px) {
    font-size: 16px;
    padding: 14px 18px;
  }
`;
export const DateLine = styled.div`
  width: 1px;
  height: 100%;
  background-color: #000;
  position: absolute;

  display: block;
`;
export const DateLine2 = styled.div`
  width: 1px;
  height: 100%;
  background-image: linear-gradient(#000, #000 80%, rgba(255, 255, 255, 0));
  position: absolute;
  display: block;
`;

// export const DateDiv = styled.div`
//   display: flex;

// `;

export const STAGEUlDiv = styled.div`
  max-width: 520px;
  flex: 1;
  box-sizing: border-box;
  display: block;
`;
export const STAGEUl = styled.ul`
  margin-bottom: 200px;
  padding-left: 32px;
  margin-top: 0;
  text-align: left;
  color: black;

  font-size: 22px;

  @media only screen and (max-width: 899px) {
    margin-bottom: 100px;
    padding-left: 32px;
    margin-top: 0;
    text-align: left;
    color: black;

    display: inline-grid;
    justify-content: center;
    width: 95%;
  }
`;
export const STAGELi = styled.li`
  font-family: "Raleway";
  margin-bottom: 12px;
  display: list-item;
  text-align: -webkit-match-parent;
  list-style-type: disc;
  color: black;
`;

// export const SectionLine = styled.div`
//   display: block;
//   height: 192px;
//   background-image: linear-gradient(#fff, #fec750);
// `;

export const Phase1Span = styled.span`
  --tw-text-opacity: 1;
  color: rgb(255 152 152 / var(--tw-text-opacity));
`;
export const Phase2Span = styled.span`
  --tw-text-opacity: 1;
  color: rgb(29 26 183 / var(--tw-text-opacity));
`;
export const Phase3Span = styled.span`
  --tw-text-opacity: 1;
  color: rgb(26 183 145 / var(--tw-text-opacity));
`;
export const Phase4Span = styled.span`
  --tw-text-opacity: 1;
  color: rgb(132 89 255 / var(--tw-text-opacity));
`;

export const LOGOText = styled.span`
  font-family: "Bungee";
  color: black;
  // padding: 10px;
  font-size: 5rem;
  display: flex;
  align-items: baseline;
`;

const Section5 = () => {
  return (
    <section id="Roadmap">
      <Roadmap>
        <RoadmapTextSec>
          {/* <RoadmapText1>
            The <LOGOText>MCMF</LOGOText>
          </RoadmapText1> */}
          <RoadmapText1>
            <LOGOText>MCMF</LOGOText>
          </RoadmapText1>
          <RoadmapText>Roadmap</RoadmapText>
        </RoadmapTextSec>

        <div style={{ display: "block" }}>
          <STAGE>
            <STAGEText>
              <STAGESpan>PHASE 1</STAGESpan>
              <STAGEP>Friendship Bracelets</STAGEP>
            </STAGEText>
            {/* ================== */}
            <DateDiv>
              <DateSec>
                <DateText>October 2023</DateText>
                <DateLine />
              </DateSec>
            </DateDiv>
            {/* ================== */}
            <STAGEUlDiv>
              <STAGEUl>
                <STAGELi>
                  Giving teddy bear dolls to NFT buyers +{" "}
                  <Phase1Span>donating children's hospitals</Phase1Span>{" "}
                  designated (2 dolls per NFT)
                </STAGELi>
                <STAGELi>
                  New Project <Phase1Span>Partnership</Phase1Span> (Hwari &
                  Airdrop) Space rental holder event, Accommodation ticket event
                </STAGELi>
                <STAGELi>
                  <Phase1Span>Collaborate with the brand</Phase1Span> to produce
                  & sell NFT clothing
                </STAGELi>
                <STAGELi>
                  Teddy Bear <Phase1Span>Dolls & Stickers</Phase1Span>{" "}
                  Production and Distribution
                </STAGELi>
                <STAGELi>
                  Teddy Bear <Phase1Span>Macaron</Phase1Span> Production and
                  Distribution
                </STAGELi>
                <STAGELi>
                  Participating in and promoting domestic/foreign conferences
                </STAGELi>
                <STAGELi>
                  NFT Holder Target <Phase1Span>Sold Out Party</Phase1Span>
                </STAGELi>
              </STAGEUl>
            </STAGEUlDiv>
          </STAGE>

          {/* ================== */}
          <STAGE>
            <STAGEText>
              <STAGESpan>PHASE 2</STAGESpan>
              <STAGEP>MCMF: The PFP Collection</STAGEP>
            </STAGEText>
            {/* ================== */}
            <DateDiv>
              <DateSec>
                <DateText>November 2023</DateText>
                <DateLine />
              </DateSec>
            </DateDiv>
            {/* ================== */}
            <STAGEUlDiv>
              <STAGEUl>
                <STAGELi>
                  NFT Lounge signed an agreement on international/internal{" "}
                  <Phase2Span>tourist attractions</Phase2Span>
                </STAGELi>
                <STAGELi>Celeb x Teddybear NFT & Goods Product Launch</STAGELi>
                <STAGELi>
                  Teddy Bear's main theme song{" "}
                  <Phase2Span>OST production</Phase2Span>
                </STAGELi>
                <STAGELi>
                  Development and sale of{" "}
                  <Phase2Span>Kakaotalk emoticons</Phase2Span>
                </STAGELi>
                <STAGELi>
                  Collaborate with domestic/foreign celebrities and projects
                </STAGELi>
                <STAGELi>
                  Multi-IP expansion, <Phase2Span>partnership</Phase2Span> with
                  global projects
                </STAGELi>
              </STAGEUl>
            </STAGEUlDiv>
          </STAGE>

          {/* ================== */}

          {/* ================== */}
          <STAGE>
            <STAGEText>
              <STAGESpan>PHASE 3</STAGESpan>
              <STAGEP>MCMF: The PFP Collection</STAGEP>
            </STAGEText>
            {/* ================== */}
            <DateDiv>
              <DateSec>
                <DateText>Desember 2023</DateText>
                <DateLine />
              </DateSec>
            </DateDiv>
            {/* ================== */}
            <STAGEUlDiv>
              <STAGEUl>
                <STAGELi>
                  <Phase3Span>Limited edition NFT</Phase3Span> drop for holders
                  of 10 or more NFTs
                </STAGELi>
                <STAGELi>
                  World Teddy Bear <Phase3Span>NFT Marketplace Open</Phase3Span>{" "}
                  (World Teddy Bear Designers IP Exchange)
                </STAGELi>
                <STAGELi>
                  <Phase3Span>Development of Coin Ecosystems</Phase3Span> (NFT
                  Staking, Token Mining) and Development of Bridging Systems
                </STAGELi>
                <STAGELi>
                  Teddybear Museum/Gallery{" "}
                  <Phase3Span>Metabus Production</Phase3Span>
                </STAGELi>
                <STAGELi>
                  Ensuring <Phase3Span>Teddy Bear Land</Phase3Span> within The
                  Sandbox and Decentral Land
                </STAGELi>
              </STAGEUl>
            </STAGEUlDiv>
          </STAGE>

          {/* ================== */}
          <STAGE>
            <STAGEText>
              <STAGESpan>PHASE 4</STAGESpan>
              <STAGEP>Access + Utility</STAGEP>
            </STAGEText>
            {/* ================== */}
            <DateDiv>
              <DateSec>
                <DateText>2024 & Beyond</DateText>
                <DateLine2 />
              </DateSec>
            </DateDiv>
            {/* ================== */}
            <STAGEUlDiv>
              <STAGEUl>
                <STAGELi>
                  NFT holder draw to visit{" "}
                  <Phase4Span>Theodore Roosevelt's</Phase4Span> birthplace in
                  Oyster Bay, New York, USA
                </STAGELi>
                <STAGELi>
                  Open <Phase4Span>Governance</Phase4Span>
                </STAGELi>
                <STAGELi>
                  ‍Teddy Bear <Phase4Span>P2E NFT Game</Phase4Span> Development
                  Starts
                </STAGELi>
                <STAGELi>
                  World <Phase4Span>Teddy Bear Day</Phase4Span> (Every October
                  27) Project Starts
                </STAGELi>
              </STAGEUl>
            </STAGEUlDiv>
          </STAGE>
        </div>
      </Roadmap>
    </section>
  );
};

export default Section5;
